import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import { Image } from 'components/Shared/Image'
import AboutNav from 'components/Public/AboutNav'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  --grid-template-columns: 1fr;
  --justify-items: start;
  ${p => p.theme.mq.md`
    --grid-template-columns: repeat(12,1fr);
    --justify-items: center;
    --align-items: start;
    `}
  ${p => p.theme.grid}
  max-width: 980px;
  min-height: 500px;
  padding-top: calc(var(--margin-default) * 2.5);
`
const Profile = styled.div`
  --justify-content: center;
  --align-items: flex-end;
  ${p => p.theme.mq.md`
    --flex-direction: column;
    --align-items: center;
    --text-align: center;
    grid-column: 2/4;
    h3 {
      margin-top: 3rem;
    }
  `}
  ${p => p.theme.flex}

  width: 100%;
  margin-bottom: var(--margin-default);

  h3,
  h4 {
    font-weight: 400;
    color: var(--color-primary);
    text-align: var(--text-align, left);
  }
  h4 {
    margin-top: -0.9rem;
  }
`
const ImageWrapper = styled.div`
  --size: clamp(12rem, 20vw, 16rem);
  width: var(--size);
  height: var(--size);
  --margin-right: 2rem;
  ${p => p.theme.mq.md`
    --margin-right: 0;
  `}
  margin-right: var(--margin-right);

  .gatsby-image-wrapper img {
    border: double 7px transparent;
    border-radius: var(--size);
    background-image: linear-gradient(white, white), var(--gradient-rainbow);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
`
const Content = styled.div`
  width: clamp(18ch, 55ch, 100%);
  margin: 0 auto calc(var(--margin-default)*2.5);
  --text-align: left;
  ${p => p.theme.mq.md`
    grid-column: 5/-2;
  `}
  
  h3 {
    margin: 2rem 0;
    color: var(--color-primary);
    opacity: 0.8;
  }
  ul {
    text-align: left;
  }
  blockquote {
    margin-top: calc(var(--margin-default)*2);
    color: var(--color-primary);
    opacity: 0.75;
    p {
      --font-size: calc(var(--font-size-default) * 0.85);
      --margin-bottom: 0;
    }
  }
`
//#endregion

const StaffDetail = ({ data, location }) => {
  location.pathname = '/about/staff'
  const {
    mdx: {
      frontmatter: { name, title },
      body,
    },
    imagefile: { childImageSharp: image },
    defaultfile: { childImageSharp: defaultImage },
  } = data

  return (
    <PageContent>
      <Seo title={name} description={`${name} biography`} />
      <AboutNav location={location} />
      <Wrapper>
        <Profile>
          <ImageWrapper>
            <Image image={image ? image : defaultImage} alt={name} />
          </ImageWrapper>
          <div>
            <h3>{name}</h3>
            <h4>{title}</h4>
          </div>
        </Profile>

        <Content>
          <MDXRenderer>{body}</MDXRenderer>
        </Content>
      </Wrapper>
    </PageContent>
  )
}

export default StaffDetail

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { type: { eq: "staff" }, slug: { eq: $slug } }) {
      frontmatter {
        name
        title
      }
      body
    }

    imagefile: file(name: { eq: $slug }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }

    defaultfile: file(name: { eq: "persona" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
